@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    
    min-height: -webkit-fill-available;
    height: 100vh;
}

.custom-scripts {
    background-color: #F5F7F8;
    font-size: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    display: flex;
    flex-direction: column;
    
    height: 100vh;
    overflow-y: hidden;
    background: #F5F7F8;
    
    min-height: -webkit-fill-available;
    font-size: 16px;
}

:root {
    --primary-color: #f6851f;
    --secondary-color: #3e5c69;
    --secondary-color-1: #4c86b7;
    --secondary-color-2: #8aaaca;
    --secondary-color-3: #c5d5e6;
    --secondary-color-4: #e2ecf4;
    --secondary-color-5: #e4ebf3;
    --support-color: #75b943;
    --background-color: #fff;
    --black-87: rgba(0, 0, 0, 0.87);
    --black-38: rgba(0, 0, 0, 0.38);
    --white: #fff;
}

body {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 28px;
    color: var(--black-87);
}

header {
    font-size: 64px;
}

h1 {
    font-size: 46px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 11px;
}

h1,
h2,
h3,
h4 {
    font-style: normal;
    font-weight: normal;
}

.admin-layout > div {
    margin-top: 0px;
    background-color: var(--background-color);
}

/* It's necessary here - its change color of material ui date time picker */
.MuiPickerDTTabs-tabs .MuiSvgIcon-root {
    fill: white;
}

#ringGroupToolTip {
    background: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    margin: 0;
}

#ringGroupToolTip > * {
    background: transparent;
    margin: 0;
}